import { render, staticRenderFns } from "./index.vue?vue&type=template&id=7ec38960&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ComponentsCarouselWithLogo: require('/home/runner/work/form3-website-frontend/form3-website-frontend/ui/Components/Carousel/WithLogo.vue').default,LayoutSpacingHorizontal: require('/home/runner/work/form3-website-frontend/form3-website-frontend/ui/Layout/Spacing/Horizontal.vue').default,LayoutSpacingVerticalHeroBody: require('/home/runner/work/form3-website-frontend/form3-website-frontend/ui/Layout/Spacing/VerticalHeroBody.vue').default})
